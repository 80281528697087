/** IE9, IE10 and IE11 requires all of the following polyfills. */

import 'core-js/es/symbol';
import 'core-js/es/object';
import 'core-js/es/function';
import 'core-js/es/parse-int';
import 'core-js/es/parse-float';
import 'core-js/es/number';
import 'core-js/es/math';
import 'core-js/es/string';
import 'core-js/es/date';
import 'core-js/es/array';
import 'core-js/es/regexp';
import 'core-js/es/map';
import 'core-js/es/weak-map';
import 'core-js/es/set';
import 'core-js/es/weak-set';
import 'core-js/es/promise';
import 'core-js/web/dom-collections';

/** Evergreen browsers require these. */
import 'core-js/es/reflect';

/** IE10 and IE11 requires the following for NgClass support on SVG elements */
// import 'classlist.js'; // Run `npm install --save classlist.js`.

import 'web-animations-js'; // Run `npm install --save web-animations-js`.

// adding conflict resolve for dev console for i.e 11
/* eslint-disable */
(window as any).__Zone_enable_cross_context_check = true;
import 'zone.js'; // Included with Angular CLI.

/**
 * Date, currency, decimal and percent pipes.
 * Needed for: All but Chrome, Firefox, Edge, IE11 and Safari 10
 */
// import 'intl'; // Run `npm install --save intl`.
// /**
//  * Need to import at least one locale-data with intl.
//  */
// import 'intl/locale-data/jsonp/en';
// /* eslint-disable */
// if (!Element.prototype.matches) {
//   Element.prototype.matches =
//     (<any>Element.prototype).msMatchesSelector || Element.prototype.webkitMatchesSelector;
// }

// (function () {
//   Object.setPrototypeOf =
//     Object.setPrototypeOf || ({ __proto__: [] } instanceof Array ? setProtoOf : mixinProperties);

//   function setProtoOf(obj, proto) {
//     obj.__proto__ = proto;
//     return obj;
//   }

//   function mixinProperties(obj, proto) {
//     for (const prop in proto) {
//       if (!obj.hasOwnProperty(prop)) {
//         obj[prop] = proto[prop];
//       }
//     }
//     return obj;
//   }
// })();
/* eslint-disable */
